import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import Navbar from './Components/NavBar';
import ProjectsPage from './Pages/ProjectsPage';
import Contact from './Pages/ContactPage';
import Footer from './Components/Footer';
import LegalMentionsPage from './Pages/LegalMentionsPage';
import Questionnaire from './Pages/Questionnaire';
import ThankYou from './Pages/Thankyou';
import CavisteDemo from './Pages/CavisteDemo';
import BikeShopDemo from './Pages/BikeShopDemo';
import SushiRestaurantDemo from './Pages/SushiRestaurantDemo';

const AppContent = () => {
  const [projects] = useState([
    { id: 1, name: 'Projet A', status: 'ongoing', deadline: '2024-07-01', progress: 70, createdAt: '2024-01-01' },
    { id: 2, name: 'Projet B', status: 'completed', deadline: '2023-12-01', progress: 100, createdAt: '2023-05-01' },
    { id: 3, name: 'Projet C', status: 'ongoing', deadline: '2024-09-01', progress: 40, createdAt: '2024-03-01' },
  ]);

  return (
    <div className="app">
      <Navbar />
      <div className="main-layout">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/projets" element={<ProjectsPage projects={projects} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Caviste" element={<CavisteDemo />} />
          <Route path="/Bike" element={<BikeShopDemo />} />
          <Route path="/sushi" element={<SushiRestaurantDemo />} />
          <Route path="/mentions-legales" element={<LegalMentionsPage />} />
          <Route path="/Questionnaire" element={<Questionnaire />} />
          <Route path="/thanks" element={<ThankYou />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;
