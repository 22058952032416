import React, { useEffect } from "react";
import "./LegalMentionsPage.css";

const LegalMentionsPage = () => {
  useEffect(() => {
    // Défiler vers le haut de la page
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="legal-container">
      <h1>Mentions Légales</h1>

      <div className="legal-content">
        <h2>Identification</h2>
        <p>Nom de l'entreprise : Agence Sunlight SARL</p>
        <p>Adresse : 14 rue Caroline Aigle, 21000 Dijon, France</p>
        <p>Téléphone : 06 10 64 59 00</p>
        <p>Email : contact@agence-sunlight.fr</p>
        <p>SIRET : 983 185 315 00014</p>

        <h2>Hébergement</h2>
        <p>Le site est hébergé par OVH SAS</p>
        <p>Adresse de l'hébergeur : 2 rue Kellermann, 59100 Roubaix, France</p>
        <p>Téléphone de l'hébergeur : 09 72 10 10 07</p>

        <h2>Propriété intellectuelle</h2>
        <p>
          Tout le contenu de ce site, incluant, de façon non limitative, les
          images, textes, vidéos, logos, et icônes sont la propriété exclusive
          de l'entreprise Agence Sunlight à l'exception des marques, logos ou
          contenus appartenant à d'autres sociétés partenaires ou auteurs.
        </p>

        <h2>Confidentialité</h2>
        <p>
          Les informations personnelles collectées par Agence Sunlight sont
          uniquement utilisées pour gérer les relations avec vous et, le cas
          échéant, pour traiter vos commandes. Elles sont confidentielles et
          conservées par Agence Sunlight.
        </p>

        <h2>Conditions d'utilisation</h2>
        <p>
          L’utilisation de ce site est réservée à un usage personnel et non
          commercial. Tout contenu téléchargé reste la propriété de Agence
          Sunlight, à l'exception de votre utilisation personnelle non
          commerciale, sans modification ni reproduction en tout ou en partie.
        </p>

        <h2>Loi applicable</h2>
        <p>
          Les présentes mentions légales sont soumises au droit français. En cas
          de litige, les tribunaux français seront seuls compétents.
        </p>

        <h2>Modification des mentions légales</h2>
        <p>
          Agence Sunlight se réserve le droit de modifier les présentes mentions
          à tout moment. L’utilisateur s’engage donc à les consulter
          régulièrement.
        </p>

        <h2>Droit d'accès et de rectification</h2>
        <p>
          Conformément à la réglementation en vigueur, vous disposez d’un droit
          d’accès, de modification, de rectification et de suppression des
          données qui vous concernent. Pour exercer ce droit, veuillez contacter
          Agence Sunlight à l’adresse ci-dessus.
        </p>
      </div>

      <div className="button-container">
        <a href="/" className="return-button">
          Retour à l'accueil
        </a>
      </div>
    </div>
  );
};

export default LegalMentionsPage;
