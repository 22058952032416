import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./HomePage.css";
import VisibilityIcon from "../assets/icone/visibilite.png";
import ImprovementIcon from "../assets/icone/productivite.png";
import MessageIcon from "../assets/icone/message.png";
import AnalyseIcone from "../assets/icone/analyse.png";
import FlecheIcone from "../assets/icone/fleche.png";
import PersoIcon from "../assets/icone/perso.png";
import TestIcone from "../assets/icone/test.png";
import StartIcone from "../assets/icone/start.png";
import Image1 from "../assets/service/classique.png";
import Image2 from "../assets/service/avance.png";
import Image3 from "../assets/service/premium.png";
import cle from "../assets/icone/design.png";
import Citation from "../assets/pdp.jpg";

function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <div className="accueil-container">
      <div className="content-container" data-aos="fade-down">
        <h1 className="titre">Sunlight</h1>
      </div>
      <div className="main-content">
        <div className="left-section" data-aos="fade-right">
          <div className="citation-container">
            <div className="citation-bubble">
              <img src={Citation} alt="Moi" className="citation-image" />
            </div>
            <p className="citation-text" style={{ whiteSpace: "pre-line" }}>
              Bienvenue ! {"\n"} Je m'appelle Loïc. créateur de sites internet
              par passion et animé par l'envie d'en faire mon métier. Mon
              objectif est simple : concevoir des sites performants et sur
              mesure qui répondent à vos besoins et reflètent votre image.
              Chaque projet est unique, je mets à profit mes compétences, ma
              créativité et mon écoute pour donner vie à vos idées. Ensemble,
              construisons un site qui fera la différence !
            </p>
          </div>
          <a href="https://calendly.com/loic-luttringer/decouvertes-de-mes-services">
            <button className="bouton">Réserver un rendez-vous</button>
          </a>
        </div>
        <div className="divider-vertical"></div>
        <div className="right-section" data-aos="fade-left">
          <div className="carte" data-aos="fade-up">
            <img src={VisibilityIcon} alt="Visibilité" />
            <h3>40%</h3>
            <p>
              des entreprises sans site web manquent des opportunités de
              visibilité.
            </p>
          </div>
          <div className="carte" data-aos="fade-up">
            <img src={ImprovementIcon} alt="Amélioration" />
            <h3>60%</h3>
            <p>
              de perte de croissance en productivité pour les entreprises sans
              présence en ligne.
            </p>
          </div>
          <div className="carte slide-in" data-aos="fade-up">
            <img src={MessageIcon} alt="Messages" />
            <h3>70%</h3>
            <p>
              des consommateurs ne font pas confiance aux entreprises sans site
              web.
            </p>
          </div>
          <p className="phrase-accrocheuse slide-in" data-aos="fade-up">
            <b>
              Ne laissez pas votre entreprise dans l'ombre. Contactez-moi dès
              aujourd'hui et commencez à rayonner sur le web!
            </b>
          </p>
        </div>
      </div>
      <div className="divider-horizontal"></div>
      <div className="extra-section" id="services-section">
        <h2 className="titre" data-aos="fade-up">
          Mon processus en 5 étapes
        </h2>
        <div className="extra-cards">
          <div className="extra-card" data-aos="fade-up">
            <img src={AnalyseIcone} alt="Analyse" />
            <h3>Analyse</h3>
            <p>Comprendre vos objectifs et besoins pour votre site web.</p>
          </div>
          <div className="extra-card" data-aos="fade-up">
            <img src={FlecheIcone} alt="Conception" />
            <h3>Conception</h3>
            <p>
              Créer des maquettes représentant le design et l'interface
              utilisateur.
            </p>
          </div>
          <div className="extra-card" data-aos="fade-up">
            <img src={PersoIcon} alt="Développement" />
            <h3>Développement</h3>
            <p>Coder le site web en utilisant les technologies appropriées.</p>
          </div>
          <div className="extra-card" data-aos="fade-up">
            <img src={TestIcone} alt="Tests" />
            <h3>Tests</h3>
            <p>
              Vérifier les fonctionnalités, l'accessibilités et les performances
              du site selon vos besoins.
            </p>
          </div>
          <div className="extra-card" data-aos="fade-up">
            <img src={StartIcone} alt="Lancement" />
            <h3>Lancement</h3>
            <p>Mise en ligne du site web et suivi de son bon fonctionnement.</p>
          </div>
        </div>
      </div>
      <div className="divider-horizontal"></div>
      <div className="container" id="services-section">
        <div className="rectangle" data-aos="fade-up">
          <div className="title">
            <img src={Image1} alt="Starter" />
            <h3>Starter</h3>
          </div>
          <div className="rectangle-content">
            <div className="details">
              <ul>
                <li>
                  <strong>Un site simple et efficace</strong> pour débuter en
                  ligne
                </li>
                <li>✔️ 1 à 4 pages personnalisées</li>
                <li>✔️ Référencement naturel (SEO de base)</li>
                <li>✔️ Site responsive (mobile & tablette)</li>
                <li>✔️ Livraison rapide sous 7 à 10 jours</li>
              </ul>
              <p>
                ❗ Un bon début, mais limité. Pour un site évolutif, optez pour
                l’<strong>Avancé</strong>.
              </p>
            </div>
          </div>
          <div className="price">
            <strong>796€</strong>
          </div>
          <div className="button-container">
            <a href="https://calendly.com/loic-luttringer/decouvertes-de-mes-services">
              <button>En savoir plus</button>
            </a>
          </div>
        </div>

        <div className="rectangle" data-aos="fade-up">
          <div className="title">
            <img src={Image2} alt="Avancé" />
            <h3>Avancé (Recommandé)</h3>
          </div>
          <div className="rectangle-content">
            <div className="details">
              <ul>
                <li>
                  <strong>Le meilleur choix</strong> pour une présence web
                  professionnelle
                </li>
                <li>✔️ 1 à 7 pages pour détailler votre activité</li>
                <li>✔️ Référencement SEO optimisé</li>
                <li>✔️ 1 à 4 mises à jour par mois</li>
                <li>
                  ✔️ Outils indispensables (formulaire, Google Maps, réseaux
                  sociaux)
                </li>
              </ul>
              <p>
                💡{" "}
                <strong>
                  Un site évolutif, bien référencé et qui grandit avec vous.
                </strong>
              </p>
            </div>
          </div>
          <div className="price">
            <strong>1249€</strong>
          </div>
          <div className="button-container">
            <a href="https://calendly.com/loic-luttringer/decouvertes-de-mes-services">
              <button>En savoir plus</button>
            </a>
          </div>
        </div>

        <div className="rectangle" data-aos="fade-up">
          <div className="title">
            <img src={Image3} alt="Premium" />
            <h3>Premium</h3>
          </div>
          <div className="rectangle-content">
            <div className="details">
              <ul>
                <li>
                  <strong>Pour aller encore plus loin</strong> avec un site
                  ultra-complet
                </li>
                <li>✔️ Site vitrine ou e-commerce</li>
                <li>✔️ 7 pages ou plus avec fonctionnalités avancées</li>
                <li>✔️ SEO avancé & optimisation continue</li>
                <li>✔️ 7 retouches par mois</li>
              </ul>
              <p>
                💡{" "}
                <strong>
                  Un site ultra-complet, mais l’Avancé est souvent suffisant
                </strong>{" "}
                pour la plupart des entrepreneurs !
              </p>
            </div>
          </div>
          <div className="price">
            <strong>1596€</strong>
          </div>
          <div className="button-container">
            <a href="https://calendly.com/loic-luttringer/decouvertes-de-mes-services">
              <button>En savoir plus</button>
            </a>
          </div>
        </div>

        <div className="rectangle" data-aos="fade-up">
          <div className="title">
            <img src={cle} alt="Modifications & Maintenance" />
            <h3>Modifications & Maintenance</h3>
          </div>
          <div className="rectangle-content">
            <div className="details">
              <ul>
                <li>
                  <strong>Faites évoluer votre site</strong> avec de nouvelles
                  fonctionnalités
                </li>
                <li>✔️ Ajout de pages, modules et outils</li>
                <li>✔️ Mises à jour régulières et optimisation SEO</li>
                <li>✔️ Corrections et évolutions pour rester compétitif</li>
              </ul>
              <p>
                🔥 <strong>Pensez à la maintenance</strong> dès{" "}
                <strong>50€/mois</strong> pour un site toujours sécurisé et
                performant !
              </p>
            </div>
          </div>
          <div className="button-container">
            <a href="https://calendly.com/loic-luttringer/decouvertes-de-mes-services">
              <button>En savoir plus</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
